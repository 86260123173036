
@font-face {
  font-family: Sequel100Black-75;
  src: url(./fonts/sequel/sequel-100-black-75.ttf);
}

@font-face {
  font-family: Inter;
  src: url(./fonts/Inter/Inter-Medium.ttf);
}

@font-face {
  font-family: BebasNeue;
  src: url(./fonts/Bebas/BebasNeue-Regular.ttf);
}
@font-face {
  font-family: Montserrat;
  src: url(./fonts/Montserrat/Montserrat-Bold.ttf);
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  /*background: #000;*/
  height: 100%;
}


.App{
  font-family: BebasNeue;
}


::-webkit-scrollbar{
  width: 3px;
  height : 3px;
}
::-webkit-scrollbar-thumb{
  background-color: rgba(255, 79, 79, 1);
  border-radius: 30px;
}

.ant-table-cell:hover{
  background: transparent!important;
}


/* modal  */
.myModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0px solid #ccc;
  background: transparent;
  overflow: auto;
  outline: none;
  border-radius: 15px;
}

.myOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 100;
}
.myOverlay2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
