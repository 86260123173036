.user_dashboard {
    /*overflow-y: auto;*/
}

.user_dashboard_header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user_dashboard_header h2 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
    color: #FFFFFF;
    text-align: center;
}

.user_dashboard_header button {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    border: 0;
    outline : none;
    height : 30px;
    border-radius : 10px;
    padding : 8px 10px;
    cursor : pointer;
}

.user_dashboard_body {
    height: calc(100vh - 145px);
    overflow: auto;
}

.user_dashboard_body_inner {
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 4fr;
    grid-gap: 20px;
}

.user_dashboard_body_inner_bloc {
    border: 4px solid rgba(255, 79, 79, 0.2);
    padding: 10px 15px;
    box-sizing: border-box;
}

.user_dashboard_body_inner_bloc p {
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 29.05px;
    text-align: center;
    color: white;
}

.user_dashboard_body_inner_bloc2_inner_inner {
    border: 4px solid rgba(255, 79, 79, 0.2);
    padding: 10px 15px;
    box-sizing: border-box;
    width: 100%;
}

.user_dashboard_body_inner_bloc2_inner2_inner {
    border: 4px solid rgba(255, 79, 79, 0.2);
    padding: 10px 15px;
    box-sizing: border-box;
}

.user_dashboard_body_inner_bloc2_inner2_inner li {
    cursor: pointer;
}

.user_dashboard_body_inner_bloc2_inner2_inner:first-child {
    border-right: 0;
    overflow-y: auto;
}

.user_dashboard_body_inner_bloc2 {
    display: inline-grid;
    grid-template-columns: 0.5fr 3fr;
    grid-gap: 20px;
}

.user_dashboard_body_inner_bloc2_inner {
    display: grid;
    grid-template-rows: 2fr 1fr;
    grid-gap: 20px;
}

.user_dashboard_body_inner_bloc_inner2 {
    display: grid;
    grid-template-columns: 1.5fr 2fr;
    grid-gap: 0;
}

.user_dashboard_body_inner_bloc2_inner_inner_link {
    margin-bottom: 10px;
}

.user_dashboard_body_inner_bloc2_inner_inner_link h2 {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    text-align: left;
    color: #FFFFFF;
}

.user_dashboard_body_inner_bloc2_inner2_inner h2 {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    text-align: left;
    color: #FFFFFF;
}

.user_dashboard_body_inner_bloc2_inner_inner_link ul {
    margin-top: 5px;
}

.user_dashboard_body_inner_bloc2_inner_inner_link ul li {
    list-style-type: none;
    margin-top: 10px;
}

.user_dashboard_body_inner_bloc2_inner_inner_link ul li a {
    text-decoration: none;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    text-align: left;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user_dashboard_body_inner_bloc2_inner_inner_link ul li a img {
    margin-right: 5px;
}

.user_dashboard_body_inner_bloc2_inner_inner_link ul li a span {
    display: flex;
    align-items: center;
}

.user_dashboard_body_inner_bloc_inner2 .user_dashboard_body_inner_bloc2_inner2_inner:first-child {
    /*background: red;*/
}

.user_dashboard_body_inner_bloc2_inner2_inner_collapse {
    /*background: green;*/
    /*height : 500px;*/
    height: 100%;
    overflow-y: scroll;
}

.user_dashboard_body_inner_bloc2_inner2_inner .ant-collapse {
    border: 0;
}

.user_dashboard_body_inner_bloc2_inner2_inner .ant-collapse-item {
    border-radius: 0;
    border-bottom: 0 !important;
}

.user_dashboard_body_inner_bloc2_inner2_inner .ant-collapse-header-text {
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    line-height: 14.52px;
    text-align: left;
    color: #FFFFFF;
}

.user_dashboard_body_inner_bloc2_inner2_inner .ant-collapse-expand-icon {
    position: absolute;
    right: 0;
    top: 22%;
    color: rgba(255, 79, 79, 1);
    font-size: 15px;
}

.user_dashboard_body_inner_bloc2_inner2_inner .ant-collapse-expand-icon .anticon {
    font-size: 16px;
}

.user_dashboard_body_inner_bloc2_inner2_inner .ant-collapse-header {
    padding: 12px 0px !important;
}

.user_dashboard_body_inner_bloc2_inner2_inner .ant-collapse-content {
    border: 0 !important;
    border-radius: 0;
    background: transparent;
}

.user_dashboard_body_inner_bloc2_inner2_inner .ant-collapse-content-active {
    background: transparent;
}

.user_dashboard_body_inner_bloc2_inner2_inner .ant-collapse-content-box {
    /*background: black;*/
    padding: 0 16px;
    border-radius: 0;
}

.user_dashboard_body_inner_bloc2_inner2_inner .ant-collapse-content-box ul li {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 12.1px;
    text-align: left;
    color: #FFFFFF;
    margin: 10px 0;
}

.user_dashboard_body_inner_bloc2_inner2_inner .ant-collapse-content-box ul li::marker {
    color: rgba(255, 79, 79, 1);
}

.user_dashboard_body_inner_bloc_inner2 .user_dashboard_body_inner_bloc2_inner2_inner:last-child {
    padding: 40px 30px;
    box-sizing: border-box;
    position: relative;
}

.user_dashboard_body_inner_bloc2_inner2_inner .ant-collapse-content-box {
    padding: 0px 16px !important;
}

.user_dashboard_body_inner_bloc2_inner2_inner_video {
    background: rgba(255, 79, 79, 0.4);
    width: 100%;
    height: 470px;
    border-radius: 50px;
    position: relative;
}

.user_dashboard_body_inner_bloc2_inner2_inner_video_true {
    background: transparent;
    width: 100%;
    height: 470px;
    border-radius: 50px;
    position: relative;
}

.user_dashboard_body_inner_bloc2_inner2_inner_video_true video {
    /*border-radius: 50px;*/
    width: 100%;
    height: 100%;
    /*background: transparent;*/
    /*position: absolute;*/
    /*top: 0;*/
    /*left: 0;*/
    /*width: 100%;*/
    /*height: 100%;*/
    /*z-index: -1;*/
}

.next-video-container {
    position: absolute;
    height: 100% !important;
    width: 100% !important;
}

@media (max-width: 1400px) {
    .user_dashboard_body_inner_bloc2_inner2_inner_video {
        height: 370px;
    }

    .user_dashboard_body_inner_bloc2_inner2_inner_video_true {
        height: 370px;
    }
}

.user_dashboard_body_inner_bloc2_inner2_inner_video_icon {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user_dashboard_body_inner_bloc2_inner2_inner_video video {
    width: 100%;
    height: 100%;
}

.user_dashboard_body_inner_bloc2_inner2_inner_notif {
    position: absolute;
    bottom: 10px;
    left: 30px;
}

.user_dashboard_body_inner_bloc2_inner2_inner_notif p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 14.52px;
    text-align: left;
    color: #FFFFFF;
}

.user_dashboard_body_inner_bloc2_inner2_inner_notif p span {
    color: rgba(255, 0, 0, 1);
}

/*carousel*/
.carousel_card {
    /*width: 100%;*/
    margin: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    /*width: 20%;*/
    height: 150px;
    border-radius: 15px;
    cursor: pointer;
}

.carousel_card img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: cover;
}

.p-carousel-prev svg {
    color: rgba(255, 79, 79, 1);
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.p-carousel-next svg {
    color: rgba(255, 79, 79, 1);
    width: 20px;
    height: 20px;
    margin-left: 10px;
}

/*carousel*/

/*responsive*/
@media (max-width: 576px) {
    .user_dashboard {
        /*overflow-y: -moz-scrollbars-none;*/
        /*background-color: yellow;*/
        height: 100%;
    }

    .user_dashboard_header {
        height: 40px;
    }

    .user_dashboard_header h2 {
        font-size: 14px;
    }

    .user_dashboard_body {
        /*height: calc(100vh - 260px);*/
        height: calc(100% - 40px);
        /*background-color: blue;*/
        /*overflow-y: auto;*/
    }

    .user_dashboard_body_inner {
        height: 100%;
        display: grid;
        grid-template-rows: 1fr 4fr;
        grid-gap: 20px;
    }

    .user_dashboard_body_inner_bloc2 {
        display: block;
        /*grid-template-columns: repeat(2, 1fr); !* Ustunlar sonini responsive qilish *!*/
        /*grid-template-rows: 1fr 1fr;*/
        /*row-gap:20px;*/
        /*grid-template-rows: 1fr 1fr;*/
    }

    .user_dashboard_body_inner_bloc2_inner {
        order: 1;
    }

    .user_dashboard_body_inner_bloc_inner2 {
        order: 0;
        margin-top: 20px;

    }

    .user_dashboard_body_inner_bloc_inner2 {
        display: block;
    }

    .user_dashboard_body_inner_bloc2_inner2_inner {
        border: 4px solid rgba(255, 79, 79, 0.2);
        padding: 10px 15px;
        box-sizing: border-box;
    }

    .user_dashboard_body_inner_bloc2_inner2_inner:first-child {
        border-right: 4px solid rgba(255, 79, 79, 0.2);
        border-bottom: 0;
    }

    .user_dashboard_body_inner_bloc2_inner2_inner_video {
        height: 220px;
    }

    .user_dashboard_body_inner_bloc2_inner2_inner_notif {
        bottom: 10px;
        left: 0px;
        width: 100%;
    }

    .user_dashboard_body_inner_bloc2_inner2_inner_notif p {
        text-align: center;
    }

    .user_dashboard_body_inner_bloc2_inner2_inner h2 {
        text-align: center;
    }

    .carousel_card {
        /*width: 45%;*/
        /*height: 90px;*/
    }

    .user_dashboard_body_inner_bloc_inner2 .user_dashboard_body_inner_bloc2_inner2_inner:last-child {
        padding: 30px 20px;
        box-sizing: border-box;
        position: relative;
    }
}