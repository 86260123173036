.library {
    /*height: 100%!important;*/
    overflow-y: auto;
}

.library_header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.library_header h2 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
    color: #FFFFFF;
    text-align: center;
}

.library_header button {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    border: 0;
    outline: none;
    height: 30px;
    border-radius: 10px;
    padding: 8px 10px;
    cursor: pointer;
}

.library_header_right {
    display: flex;
    align-items: center;
}

.library_header_right h3 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    color: #FFFFFF;
    text-align: center;
    margin: 0 15px;
}

.library_body {
    position: relative;
    height: calc(100vh - 145px);
    overflow-x: auto;
    border: 4px solid rgba(255, 79, 79, 0.2);
    padding: 10px;
}

.library_body_search {
    height: 40px;
    width: 100%;
    border: 2px solid rgba(255, 79, 79, 0.2);
    border-radius: 20px;
    display: flex;
    align-items: center;
    /*justify-content: center;*/
    padding: 0 15px;
    /*background: red;*/
}

.library_body_search img {
    /*width: 5%;*/
}

.library_body_search input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: transparent;
    margin-left: 10px;
    color: #FFFFFF;
    font-weight: 500;
    font-family: Inter;
    font-size: 15px;
}

.library_body_table {
    height: 100%;
}

.library_body_table_text {
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 12.1px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.library_body_table_text_image {
    widht: 25px;
    height: 25px;
}

.library_body_table_text_pay {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    max-width: 150px;
    height: 30px;
    gap: 0px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    cursor: pointer;
}

.library_body_table_text_no_pay {
    border: 1px solid rgba(244, 244, 244, 1);
    max-width: 150px;
    height: 30px;
    gap: 0px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    cursor: pointer;
}

.library_body_pagination {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

/*pagination*/
.library_body_pagination .ant-pagination-item a {
    font-family: Inter;
    font-weight: 700;
    font-size: 12px;
    color: rgba(255, 79, 79, 1) !important;
}

.library_body_pagination .ant-pagination-item-link {
    color: rgba(255, 79, 79, 1) !important;
}

.library_body_pagination .ant-pagination-item-ellipsis {
    color: rgba(255, 79, 79, 1) !important;
}

.library_body_pagination .ant-pagination-item-link-icon {
    color: rgba(255, 79, 79, 1) !important;
}

.library_body_pagination .ant-pagination-item-active {
    border-color: rgba(255, 79, 79, 1) !important;
    background: transparent !important;
}

.library_body_pagination .ant-pagination-options {
    background: transparent !important;
    color: rgba(255, 79, 79, 1) !important;
}

/*pagination*/

/*table style*/
.library_body_table .ant-table {
    height: calc(100vh - 213px);
    overflow-y: auto;
}

.library_body_table .ant-table {
    border-radius: 0 !important;
    /*height: 100%!important;*/
}

.library_body_table .ant-table-content {

}

.library_body_table .ant-table-cell {
    /*background: blue!important;*/
    border-radius: 0 !important;
    font-family: Inter;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 19.36px !important;
    color: #FFFFFF !important;
    text-align: center !important;
}

.library_body_table .ant-table {
    background: transparent !important;
}

.library_body_table .ant-table-thead .ant-table-cell {
    border-bottom: 0px solid rgba(255, 79, 79, 0.2) !important;
    background: transparent !important;
    /*position: sticky;*/
    /*top : 0;*/
    z-index: 100;
}

.library_body_table .ant-table-tbody {
    /*background: green!important;*/
    /*    height:100%!important;*/
    /*    overflow-y: auto!important;*/
}

.library_body_table .ant-empty-description {
    display: none;
}

.library_body_table .ant-table-tbody .ant-table-cell {
    border: 0 !important;
}

.library_body_table .ant-table-tbody .ant-table-cell-row-hover {
    background: rgba(255, 79, 79, 0.2) !important;
}

.library_body_table .ant-table-placeholder {
    background: transparent !important;
}

.library_body_table .ant-table-placeholder:hover {
    background: transparent !important;
}

.library_body_table .ant-table-placeholder:hover {
    background: rgba(255, 79, 79, 0.2) !important;
}

.library_body_table .ant-table-cell:hover {
    /*background: transparent!important;*/
}

/*table style*/

.library_body_modal {

}

.library_body_modal_inner {
    background: rgba(23, 23, 23, 1);
    width: 350px;
    height: auto;
    padding: 10px 20px;
}
.library_body_modal_inner .ant-radio-wrapper span{
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 10px;
    text-align: center;
    color: #FFFFFF;
}
.library_body_modal_inner_addModal_inner_text {
    padding: 10px 0;
}

.library_body_modal_inner_addModal_inner_text h2 {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 29.05px;
    text-align: center;
    color: #FFFFFF;
}

.library_body_modal_inner_addModal_inner_text_p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 29.05px;
    text-align: center;
    color: #FFFFFF;
    margin: 5px 0;
}

.library_body_modal_inner {

}

.library_body_modal_inner .ant-form-item-required {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    /*line-height: 29.05px;*/
    text-align: center;
    color: #FFFFFF !important;
}

.library_body_modal_inner .ant-input {
    border-color: white;
    /*background: rgba(23, 23, 23, 1);*/
    background: transparent;
    font-family: Inter;
    font-size: 15px;
    font-weight: 600;
    color: white;
    height: 35px;
    /*line-height: 24.2px;*/
}

.library_body_modal_inner .ant-input::placeholder {
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    color: gray;
    /*line-height: 24.2px;*/
}

.library_body_modal_inner .ant-input:hover {
    border-color: white;
}

/*class="ant-input ant-input-status-error css-dev-only-do-not-override-gzal6t css-dev-only-do-not-override-gzal6t"*/
.library_body_modal_inner .ant-input-status-error .ant-input {
    border-color: #ff4d4f !important;
}

.library_body_modal_inner input::-webkit-inner-spin-button {
    display: none;
}

.library_body_modal_inner .ant-form-item-explain-error {
    font-family: Inter;
}

.library_body_modal_inner .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
    background: transparent !important;
    border-width: 1px;
    border-style: solid;
    border-color: #ff4d4f;
}


.library_body_modal_inner_addModal_inner_buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}

.library_body_modal_inner_addModal_inner_buttons button {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: center;
    padding: 8px 10px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    width: 120px;
    height: 35px;
    cursor: pointer;
}

.library_body_modal_inner_addModal_inner_buttons button:first-child {
    border: 1px solid rgba(244, 244, 244, 1);
    background: rgba(23, 23, 23, 1);
    color: white;
}

.library_body_modal_inner_addModal_inner_buttons button:last-child {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    color: white;
}

.table_icons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.table_icons svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
    font-weight: 700;
    margin: 0 10px;
}
.table_icons_mentor{
    /*color : white;*/
}

.table_icons_send {
    color: blue;
}

.table_icons_edit svg {
    color: #52c41a;
}

.table_icons_delete svg {
    color: rgba(255, 79, 79, 1);
}

/*responsive*/

@media (max-width: 576px) {
    .library_header h2 {
        font-size: 14px;
    }

    .library_body {
        height: calc(100vh - 260px);
        /*height: 100%;*/
        /*overflow-y: auto;*/
    }

    .library_body_table .ant-table-cell {
        font-size: 12px !important;
    }

    .library_body_table_text_pay {
        width: auto;
        padding: 0 5px;
    }

    .library_body_table_text_no_pay {
        width: auto;
        padding: 0 5px;
    }

    .library_body_table .ant-table {
        height: calc(100vh - 323px);
        overflow-y: auto;
    }

    .library_header_right h3 {
        font-family: Inter;
        font-size: 10px;
        font-weight: 700;
        line-height: 10px;
        color: #FFFFFF;
        text-align: center;
        margin: 0 5px;
    }
}