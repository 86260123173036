.all_courses {
    /*height: 100%!important;*/
    overflow-y: auto;
}

.all_courses_header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.add_test_modal .ant-select-selector {
    background: rgba(23, 23, 23, 1) !important;
}

.add_test_modal .ant-select-selection-item {
    font-family: Inter;
    font-size: 15px;
    font-weight: 600;
    color: white;
}

.add_test_modal .ant-select-selection-placeholder {
    font-family: Inter;
    font-size: 15px!important;
    font-weight: 400!important;
    color: gray !important;
}


.add_test_modal .ant-select-arrow svg {
    color: white;
}

.add_test_modal .ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
.ant-select-selector {
    border-color: #FFFFFF;
}


.all_courses_header button {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    border: 0;
    outline: none;
    height: 30px;
    border-radius: 10px;
    padding: 8px 10px;
    cursor: pointer;
}

.all_courses_header h2 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
    color: #FFFFFF;
    text-align: center;
}

.all_courses_body {
    height: calc(100vh - 145px);
    border: 4px solid rgba(255, 79, 79, 0.2);
    padding: 20px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 10px;
    /*height: 100%;*/
    overflow-y: auto;
}


.all_courses_body_card {
    border: 2px solid rgba(255, 79, 79, 0.2);
    border-radius: 20px;
    text-align: center;
    /*height: 200px;*/
    /*display: grid;*/
    /*grid-template-rows: 0fr 0.5fr 1fr;*/
    /*grid-gap: 10px;*/
    padding: 10px;
}

.all_courses_body_inner {
    display: grid;
    grid-template-rows: 0.5fr 2fr;
    grid-template-columns: 100%;
    grid-gap: 10px;
    margin-top: 10px;
    width: 100%;
}

.all_courses_body_card_header {
    width: 100%;
    height: 180px;
    position: relative;
}

.all_courses_body_card_header .ant-image-mask {
    border-radius: 20px !important;
}

.all_courses_body_card_header_icons {
    position: absolute;
    top: 15px;
    right: 15px;
    background: #5f5f5f;
    /*background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);*/
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.all_courses_body_card_header_price{
    position: absolute;
    bottom: 10px;
    left: 15px;
}
.all_courses_body_card_header_price p{
    font-family: Inter;
    font-size: 13px;
    font-weight: 700;
    color: #52c41a;
}
.all_courses_body_card_header_icons .table_icons_add {
    color: white;
}

.all_courses_body_card_body {
    display: flex;
    align-items: center;
    justify-content: center;
    /*background: green;*/
    width: 100%;
}

.all_courses_body_card_body p {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    /*line-height: 18px;*/
    color: white;
    width: 250px;
    overflow-x: scroll;
}

@media (max-width: 1400px) {
    .all_courses_body_card_header {
        width: 100%;
        height: 120px;
    }
}

.all_courses_body_card_header img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
}

.all_courses_body_card_body_icons {
    display: flex;
    align-items: center;
}

.all_courses_body_card_body_icons svg {
    cursor: pointer;
}

.all_courses_body_card_body_icons .table_icons_open {
    margin-right: 8px;
    display: flex;
    align-items: center;
}

.table_icons_open svg {
    color: orange;
}

.all_courses_body_card_body_icons .table_icons_edit {
    margin-right: 8px;
    display: flex;
    align-items: center;
}

.all_courses_body_card_body_icons .table_icons_delete {
    display: flex;
    align-items: center;
}

.all_courses_body_card_body_icons .table_icons_add {
    margin-right: 8px;
    color: white;
    display: flex;
    align-items: center;
}

.all_courses_body_card_edit_delete {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.all_courses_body_card_edit_delete_text {
    /*display: grid;*/
    /*grid-template-columns: 3fr 1fr;*/
    /*grid-gap : 0;*/
    max-width: 200px;
    overflow-x: hidden;
}

.all_courses_body_card_edit_delete_text h2 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    /*line-height: 20px;*/
    color: #FFFFFF;
}

.all_courses_body_card_edit_delete_publish {
    /*background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);*/
    background: green;
    border-radius: 10px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 14.52px;
    text-align: center;
    color: #FFFFFF;
    width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.all_courses_body_card_edit_delete_unPublish {
    background: transparent;
    border: 1px solid white;
    border-radius: 10px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 14.52px;
    text-align: center;
    color: #FFFFFF;
    width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.all_courses_body_card_body_button {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    border-radius: 10px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 14.52px;
    text-align: center;
    color: #FFFFFF;
    width: 120px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    /*margin: 0 auto;*/
    cursor: pointer;
}


.add_test_modal {
    background: rgba(23, 23, 23, 1);
    width: 350px;
    max-height: 570px;
    overflow-y: auto;
    padding: 10px 20px;
}

.add_test_modal_radio {
    /*display : flex;*/
    /*align-items: center;*/
    /*justify-content: space-between;*/
}

.add_test_modal_radio p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    /*line-height: 10px;*/
    /*text-align: center;*/
    color: #FFFFFF;
}

.add_test_modal .ant-radio-wrapper span {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 10px;
    text-align: center;
    color: #FFFFFF;
}

.add_test_modal_addModal_inner_text {
    padding: 10px 0;
}

.add_test_modal_addModal_inner_text h2 {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 29.05px;
    text-align: center;
    color: #FFFFFF;
}

.add_test_modal_addModal_inner_text_p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 29.05px;
    text-align: center;
    color: #FFFFFF;
    margin: 5px 0;
}

.add_test_modal {

}

.add_test_modal .ant-form-item-required {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    /*line-height: 29.05px;*/
    text-align: center;
    color: #FFFFFF !important;

}

.add_test_modal .ant-input {
    border-color: white;
    /*background: rgba(23, 23, 23, 1);*/
    background: transparent;
    font-family: Inter;
    font-size: 15px;
    font-weight: 600;
    color: white;
    height: 35px;
    /*line-height: 24.2px;*/
}

.add_test_modal .ant-input::placeholder {
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    color: gray;
    /*line-height: 24.2px;*/
}

.add_test_modal .ant-form-item {
    margin-bottom: 10px !important;
}

.add_test_modal .ant-input:hover {
    border-color: white;
}

/*class="ant-input ant-input-status-error css-dev-only-do-not-override-gzal6t css-dev-only-do-not-override-gzal6t"*/
.add_test_modal .ant-input-status-error .ant-input {
    border-color: #ff4d4f !important;
}

.add_test_modal input::-webkit-inner-spin-button {
    display: none;
}

.add_test_modal .ant-form-item-explain-error {
    font-family: Inter;
}

.add_test_modal .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
    background: transparent !important;
    border-width: 1px;
    border-style: solid;
    border-color: #ff4d4f;
}

.add_test_modal .ant-form-item-control-input {
    /*height : 0!important;*/
}
.add_test_modal .ant-form-item-control-input-content textarea{
    height : 70px;
}


.add_test_modal_addModal_inner_buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 10px;
}

.add_test_modal_addModal_inner_buttons button {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: center;
    padding: 8px 10px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    width: 120px;
    height: 35px;
    cursor: pointer;
}

.add_test_modal_addModal_inner_buttons button:first-child {
    border: 1px solid rgba(244, 244, 244, 1);
    background: rgba(23, 23, 23, 1);
    color: white;
}

.add_test_modal_addModal_inner_buttons button:last-child {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    color: white;
}


.test_list {
    background: rgba(23, 23, 23, 1);
    width: 350px;
    max-height: 570px;
    overflow-y: auto;
    padding: 10px 20px;
}

.add_test_modal_list_header h2 {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 29.05px;
    text-align: center;
    color: #FFFFFF;
    margin: 10px 0;
}

.test_list_add {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 10px;
}

.test_list_add .test_list_add_inner {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    border-radius: 10px;
    font-family: Inter;
    font-size: 15px;
    font-weight: 700;
    /*line-height: 14.52px;*/
    text-align: center;
    color: #FFFFFF;
    width: 120px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    /*margin: 0 auto;*/
    cursor: pointer;
}

.test_list_add_inner svg {
    font-size: 17px;
    font-weight: 700;
}

.add_test_modal_list {
    /*height: 35px;*/
    border-bottom: 1px solid gray;
    /*border-radius: 10px;*/
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.add_test_modal_list:hover {
    background: black;
}

.add_test_modal_list_left p {
    font-family: Inter;
    font-size: 15px;
    font-weight: 700;
    /*line-height: 29.05px;*/
    /*text-align: center;*/
    color: #FFFFFF;
}

.add_test_modal_list_right {
    display: flex;
    align-items: center;
}

.add_test_modal_list_right svg {
    cursor: pointer;
}

.add_test_modal_list_right .table_icons_edit {
    margin-right: 8px;
}

.add_test_modal_list_footer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.add_test_modal_list_footer_button {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    border-radius: 10px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 14.52px;
    text-align: center;
    color: #FFFFFF;
    width: 120px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
    cursor: pointer;
}

@media (max-width: 576px) {

    .all_courses_header {
        height: 40px;
    }

    .all_courses_header h2 {
        font-size: 14px;
    }

    .all_courses_body {
        height: calc(100vh - 260px);
        /*height: 100%;*/
        /*overflow-y: auto;*/
    }

    .all_courses_body {
        height: calc(100vh - 260px);
        padding: 20px;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;
        /*height: 100%;*/
        overflow-y: auto;
    }

    .all_courses_body_card_header {
        width: 100%;
        height: 200px;
    }
}

