.layout {
    width: 100%;
    height: 100vh;
    display: flex;
    position: relative;
}

.layout_back_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover; /* This ensures the image covers the entire container */
    z-index: -1;
    /*opacity: 100%;*/
    background: black;
}

.layout_menu {
    /*background: black;*/
    width: 90px;
    height: 100%;
    box-shadow: 0px 4px 50px 0px rgba(255, 0, 0, 0.3);
    /*z-index: 1000;*/
}

.layout_menu_logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;
    /*background: black;*/
}

.layout_menu_body {
    height: calc(100% - 75px);
    overflow-y: auto;
    padding: 15px 10px;
}

.layout_menu_body_link {
    text-align: center;
    margin-top: 15px;
}

.layout_menu_body a:first-child .layout_menu_body_link {
    margin-top: 0px;
}
.layout_menu_body_link img{
    width: 30px;
    height: 30px;
    filter : brightness(0) saturate(100%) invert(50%) sepia(43%) saturate(5852%) hue-rotate(331deg) brightness(102%) contrast(100%);
}
.layout_menu_body_link:hover img {
    filter: brightness(0) saturate(100%) invert(13%) sepia(100%) saturate(4834%) hue-rotate(356deg) brightness(97%) contrast(117%) !important;
}

.layout_menu_body_link:hover p {
    color: rgba(255, 0, 0, 1) !important;
}

.layout_menu_body_link p {
    font-family: Inter;
    font-size: 9px;
    font-weight: 700;
    line-height: 10.89px;
    text-align: center;
    color: white;
}

.active_link img {
    filter: brightness(0) saturate(100%) invert(13%) sepia(100%) saturate(4834%) hue-rotate(356deg) brightness(97%) contrast(117%) !important;
}

.active_link p {
    color: rgba(255, 0, 0, 1) !important;
    /*color: yellow !important;*/
}

.layout_body {
    width: calc(100% - 90px);
    height: 100%;
    /*background: black;*/
    /*background-image: url('../../images/user/adminDashboard/back2.png');*/
    /*background-size: 100%;*/
    /*background-size: cover;*/
    /*background-repeat: no-repeat;*/
    /*background-position: 0px 160px;*/
    /*background-position: center;*/
}

/*header*/
.layout_body_header {
    /*background: rgba(0, 0, 0, 1);*/
    /*position: sticky;*/
    top: 0;
    /*z-index: 100;*/
    height: 75px;
    color: #FFFFFF;
    padding: 0 15px;
}

.header_line {
    width: 100%;
    height: 1px;
    background: rgba(192, 183, 232, 0.33);
}

.header_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100% !important;
    height: 100% !important;
}

.header_inner_mobile {
    display: none;
}

.header_left {
    color: white;
}

.header_left h1 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
}

.header_left h2 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
}

.header_right {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header_right_setting {
    border: 2px solid rgba(244, 244, 244, 1);
    height: 48px;
    width: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-right: 15px;
    cursor: pointer;
}


.header_right_setting_radio {
    display: none;
}

.header_right_setting:hover {
    width: 180px;
    border-radius: 50px;
}

.header_right_setting:hover .header_right_setting_radio {
    display: block;
}

.header_right_setting_radio .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
    background-color: transparent;
}

.header_right_setting_radio .ant-radio-wrapper .ant-radio-inner {
    background-color: transparent;
}

.header_right_setting_radio .ant-radio-wrapper:hover .ant-radio-inner {
    border-color: rgba(255, 79, 79, 1);
}

.header_right_setting_radio .ant-radio-wrapper .ant-radio-checked::after {
    border: none;
    background-color: rgba(255, 79, 79, 1);
}

.header_right_setting_radio .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: transparent;
    background-color: rgba(255, 79, 79, 1);
}

.header_right_name {
    border: 2px solid rgba(244, 244, 244, 1);
    height: 48px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    cursor: pointer;
}

.header_right_name p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    color: rgba(255, 79, 79, 1);
    margin: 0 15px;
}

.header_right_notif {
    border: 2px solid rgba(244, 244, 244, 1);
    height: 48px;
    width: 48px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    cursor: pointer;
    position: relative;
}

.header_right_notif_text {
    position: absolute;
    height: 18px;
    width: 18px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(218, 30, 40, 1);
    top: 3px;
    right: 3px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
}

.read {
    color: gray !important;
}

.noRead {
    color: rgba(218, 30, 40, 1);
    font-weight: 700;
}

.header_right_notif_title {
    padding: 10px 12px;
    padding-top: 10px;
}

.header_right_notif_title p {
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 19.36px;
    cursor: pointer;
    color: white;
    text-align: center;
}

.header_right_notif_body {
    max-height: 200px;
    overflow-y: auto;
}

.header_right_notif_body_clear {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    cursor: pointer;
    color: white;
    text-align: center;
}

.header_right_notif_popover {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
    padding: 10px 12px;
    margin: 0px 0;
    border-bottom: 1px solid #282828;
    cursor: pointer;

    word-wrap: break-word;
    word-break: break-all;
}

.header_right_notif_popover:hover {
    /*background-color: #282828 !important;*/
    background-color: black !important;
}

.header_right_notif_popover_clear {
    padding: 0px 12px;
    padding-top: 10px;
    display: flex;
    justify-content: right;
}

.header_right_notif_popover_clear button {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    cursor: pointer;
    /*color: white;*/
    text-align: center;
    padding: 5px 10px;
    border-radius: 10px;
    border: 0;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    width: 120px;
    height: 35px;
    cursor: pointer;
}

/*.header_right_notif_popover_clear button{*/
/*    border: 1px solid rgba(244, 244, 244, 1);*/
/*    background: rgba(23, 23, 23, 1);*/
/*    color : white;*/
/*}*/
.header_right_notif_popover_clear button {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    color: white;
}


.ant-popover-inner {
    background: #171717 !important;
    opasity: 1 !important;
    background-clip: padding-box;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    padding: 10px 0 !important;
}


.ant-popover-inner-content {
    width: 310px !important;
}

.ant-popover-arrow {
    display: none !important;
}


.header_right_settings {
    border: 2px solid rgba(244, 244, 244, 1);
    height: 48px;
    width: 48px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    cursor: pointer;
}

.header_right_settings a {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}


.log_out {
    width: 280px;
    height: 100px;
    border-radius: 10px;
    background: rgba(23, 23, 23, 1);
    /*background: red;*/
    padding: 20px 10px;
    margin: 0 auto;
}

.log_out_text h2 {
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 19.36px;
    color: white;
    text-align: center;
}

.log_out_buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 20px 0;
}

.log_out_buttons button {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: center;
    padding: 8px 10px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    width: 120px;
    height: 35px;
    cursor: pointer;
}

.log_out_buttons button:first-child {
    border: 1px solid rgba(244, 244, 244, 1);
    background: rgba(23, 23, 23, 1);
    color: white;
}

.log_out_buttons button:last-child {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    color: white;
}

.header_right_logOut .ant-popover-inner {
    background: green !important;
    opasity: 1 !important;
    background-clip: padding-box;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    padding: 10px 0 !important;
}


/*header*/


.layout_body_inner {
    height: calc(100vh - 75px);
    padding: 15px !important;
}

@media (max-width: 576px) {
    .layout {
        /*position: relative;*/
        /*display: grid;*/
        /*align-content: space-between;*/
        height: 100vh;
        overflow-y: hidden;

        display: flex;
        flex-direction: column-reverse;
    }

    .layout_body {
        /*order: 1;*/
    }

    .layout_menu {
        /*position: fixed;*/
        /*bottom: 0;*/
        /*left: 0;*/
        width: 100%;
        height: 80px;
        /*z-index: 1000;*/
        overflow-x: auto;
        /*display: none;*/
        /*order: 2;*/
    }


    .layout_menu_logo {
        display: none;
    }

    .layout_menu_body {
        max-width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        overflow-x: auto;
        padding: 0 15px;
    }

    .layout_menu_body_link {
        text-align: center;
        margin: 0 10px;
        height: 60px;
        width: 60px;
        opacity: 0.3;
    }

    .layout_menu_body_link img {
        width: 30px;
        height: 30px;
        /*filter: brightness(0) saturate(100%) invert(54%) sepia(63%) saturate(5645%) hue-rotate(333deg) brightness(107%) contrast(101%)!important;*/
    }

    .layout_menu_body_link:hover {
        opacity: 1;
    }

    .layout_menu_body_link:hover img {
        /*box-shadow: 0px 0px 10px 0px rgba(255, 0, 0, 1);*/
        width: 35px;
        height: 35px;
    }

    .layout_menu_body_link:hover p {

    }

    .active_link {
        opacity: 1;
    }

    .active_link img {
        width: 35px;
        height: 35px;
    }

    .active_link p {

    }

    .layout_body {
        width: 100%;
        height: calc(100vh - 80px);
        /*background: red;*/
    }

    .layout_body_header {
        width: 100%;
        height: 120px;
        padding: 0;
        position: relative;
        /*top : 0!important;*/
        /*left: 0;*/
    }

    .layout_body_inner {
        height: calc(100% - 120px);
        /*overflow-y: auto;*/
    }

    .header_inner {
        display: none;
    }

    .header_inner_mobile {
        display: block;
    }

    .header_inner_mobile_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 70px;
        border: none;
        padding: 0 15px;
        box-shadow: 0px 4px 30px 0px rgba(255, 0, 0, 0.3);
    }

    .header_inner_mobile_logo {
        display: flex;
        align-items: center;
    }

    .header_inner_mobile_logo img {
        height: 55px;
        width: 55px;
    }

    .header_right_setting_radio {
        display: block;
    }

    .header_right_setting {
        display: flex;
        align-items: center;
        border: none;
        margin-right: 0px;
        width: auto;
        border-radius: 0px;
    }

    .header_right_setting:hover {
        width: auto;
        border-radius: 0px;
    }

    .header_inner_mobile_icon {
        height: 40px;
        display: flex;
        align-items: center;
        position: relative;
    }

    .header_inner_mobile_icon .header_inner_mobile_icon_settings a {
        display: flex;
    }

    .header_inner_mobile_icon_notif {
        background: rgba(218, 30, 40, 1);
        width: 18px;
        height: 18px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Inter;
        font-size: 12px;
        font-weight: 700;
        position: absolute;
        top: 0;
        right: -7px;
    }

    .header_right_name {
        border: 0px solid rgba(244, 244, 244, 1);
        height: 100%;
        padding: 0 0px;
    }

    .header_inner_mobile_bottom {
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 15px;
    }

    .header_inner_mobile_bottom .header_left h1 {
        font-size: 14px;
        line-height: 20px;
    }

    .header_inner_mobile_bottom .header_left h2 {
        font-size: 10px;
        line-height: 15px;
    }

    .header_line {
        padding: 0 15px;
    }

    ::-webkit-scrollbar {
        display: none !important;
    }


    .header_right_notif_mobile {
        display: block !important;
    }

    .header_right_notif_decktop {
        display: none !important;
    }
}

a {
    text-decoration: none;
}