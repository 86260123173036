.result_modal {
    width: 650px;
    height: 400px;
    border-radius: 10px;
    background: rgba(23, 23, 23, 1);
    /*z-index: 1000000;*/
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding : 0 20px;
}

.result_modal_inner {

}

.result_modal_inner_icon h1 {
    font-family: Inter;
    font-size: 35px;
    font-weight: 700;
    line-height: 48.41px;
    color: white;
    margin: 10px 0;
}

.result_modal_inner_text {

}

.result_modal_inner_text p {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 29.05px;
    color: white;
}

.result_modal_inner_button {
    margin-top: 20px;
}

.result_modal_inner_button button {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    border-radius: 40px;
    border : 0;
    outline : none;
    padding : 10px 20px;

    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 24.2px;
    text-align: left;
    color: white;
    cursor : pointer;
}

@media(max-width: 576px) {
    .result_modal {
        width: 350px;
        height: auto;
        padding : 20px 10px;
    }
}