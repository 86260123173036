.cooperation {
    /*height: 100%!important;*/
    /*overflow-y: auto;*/
}

.cooperation_header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cooperation_header h2 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
    color: #FFFFFF;
    text-align: center;
}

.cooperation_body {
    height: calc(100vh - 145px);
    border: 4px solid rgba(255, 79, 79, 0.2);
    overflow-y: auto;
}
.cooperation_body_modal {
    background: rgba(23, 23, 23, 1);
    width: 350px;
    height: auto;
    padding : 10px 20px;
}
.cooperation_body_modal_field{
    margin-top : 10px;
}

.cooperation_body_modal_field .ant-form-item-required{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    /*line-height: 29.05px;*/
    text-align: center;
    color: #FFFFFF!important;
}
.cooperation_body_modal_field .ant-input {
    border-color: white;
    /*background: rgba(23, 23, 23, 1);*/
    background: transparent;
    font-family: Inter;
    font-size: 15px;
    font-weight: 600;
    color: white;
    height: 35px;
    /*line-height: 24.2px;*/
}

.cooperation_body_modal_field .ant-input::placeholder {
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    color: gray;
    /*line-height: 24.2px;*/
}

.cooperation_body_modal_field .ant-input:hover {
    border-color: white;
}

/*class="ant-input ant-input-status-error css-dev-only-do-not-override-gzal6t css-dev-only-do-not-override-gzal6t"*/
.cooperation_body_modal_field .ant-input-status-error .ant-input {
    border-color: #ff4d4f !important;
}

.cooperation_body_modal_field input::-webkit-inner-spin-button {
    display: none;
}

.cooperation_body_modal_field .ant-form-item-explain-error {
    font-family: Inter;
}

.cooperation_body_modal_field .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
    background: transparent !important;
    border-width: 1px;
    border-style: solid;
    border-color: #ff4d4f;
}
.cooperation_body_modal_field .ant-radio-wrapper span{
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    color: white;
}






.cooperation_body_modal_inner_text{
    padding : 10px 0;
}
.cooperation_body_modal_inner_text h2 {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 29.05px;
    text-align: center;
    color: #FFFFFF;
}

.cooperation_body_table {
    height: 100%;
    position: relative;
}

.cooperation_body_table_text {
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 12.1px;
    text-align: center;
    color: #FFFFFF;
}

.cooperation_body_table_text_pay {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    width: 109px;
    height: 30px;
    gap: 0px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    cursor : pointer;
}

.cooperation_body_table_text_no_pay {
    border: 1px solid rgba(244, 244, 244, 1);
    width: 109px;
    height: 30px;
    gap: 0px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    cursor : pointer;
}

/*table style*/
.cooperation_body_table .ant-table {
    /*height: calc(100vh - 345px);*/
    /*overflow-y: auto;*/
}

.cooperation_body_table .ant-table {
    border-radius: 0 !important;
    /*height: 100%!important;*/
}

.cooperation_body_table .ant-table-content {

}

.cooperation_body_table .ant-table-cell {
    /*background: blue!important;*/
    border-radius: 0 !important;
    font-family: Inter;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 19.36px !important;
    color: #FFFFFF !important;
    text-align: center !important;
}

.cooperation_body_table .ant-table {
    background: transparent !important;
}

.cooperation_body_table .ant-table-thead .ant-table-cell {
    border-bottom: 4px solid rgba(255, 79, 79, 0.2) !important;
    background: transparent !important;
    /*position: sticky;*/
    /*top : 0;*/
    z-index: 100;
}

.cooperation_body_table .ant-table-tbody {
    /*background: green!important;*/
    /*    height:100%!important;*/
    /*    overflow-y: auto!important;*/
}

.cooperation_body_table .ant-empty-description {
    display: none;
}

.cooperation_body_table .ant-table-tbody .ant-table-cell {
    border: 0 !important;
}

.cooperation_body_table .ant-table-tbody .ant-table-cell-row-hover {
    background: rgba(255, 79, 79, 0.2) !important;
}

.cooperation_body_table .ant-table-placeholder {
    background: transparent !important;
}

.cooperation_body_table .ant-table-placeholder:hover {
    background: rgba(255, 79, 79, 0.2) !important;
}

/*table style*/



@media(max-width: 576px) {

    .cooperation_header {
        height: 40px;
        width: 100%;
        /*background-color: green;*/
    }

    .cooperation_header_inner_t {
        margin-top: 5px;
    }
    .cooperation_header h2 {
        font-size: 14px;
    }
    .cooperation_header_body {
        display: block;
    }
    .cooperation_header_body p {
        font-size: 12px;
    }
    .cooperation_header_inner_table_inner{
        padding : 0;
    }
    .cooperation_header_inner_table_line {
        width: 100%;
        /*margin-bottom: 5px;*/
        /*display: none;*/
    }
    .cooperation_body_table .ant-table {
        /*height: calc(100vh - 570px);*/
        /*overflow-y: auto;*/
    }
    .cooperation_body {
        height: calc(100vh - 260px);
        /*overflow-y: auto;*/
    }


    .cooperation_body_table .ant-table-cell {
        font-size: 14px !important;
        font-weight: 500 !important;
    }
}