.settings {
    /*height: 100%!important;*/
    overflow-y: auto;
}

.settings_header {
    height: 40px;
    display: flex;
    align-items: center;
    /*justify-content: s;*/
}

.settings_header h2 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
    color: #FFFFFF;
    /*text-align: center;*/
}

.settings_body {
    height: calc(100vh - 145px);
    border: 4px solid rgba(255, 79, 79, 0.2);
    padding: 20px;
    box-sizing: border-box;
    grid-gap: 10px;
    /*height: 100%;*/
    overflow-y: auto;
}

.settings_body_form {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
}

.settings_body_form_inner_field_text {
    /*margin-bottom: 10px;*/
    height: 30px;
}

.settings_body_form_inner_field_text p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    /*line-height: 19.36px;*/
    color: white;
}

.settings_body_form_inner_field .ant-input {
    border-color: white;
    /*background: rgba(23, 23, 23, 1);*/
    background: transparent;
    font-family: Inter;
    font-size: 15px;
    font-weight: 600;
    color: white;
    height: 35px;
    /*line-height: 24.2px;*/
}

.settings_body_form_inner_field .ant-input::placeholder {
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    color: gray;
    /*line-height: 24.2px;*/
}

.settings_body_form_inner_field .ant-input:hover {
    border-color: white;
}

/*class="ant-input ant-input-status-error css-dev-only-do-not-override-gzal6t css-dev-only-do-not-override-gzal6t"*/
.settings_body_form_inner_field .ant-input-status-error .ant-input {
    border-color: #ff4d4f !important;
}

.settings_body_form_inner_field input::-webkit-inner-spin-button {
    display: none;
}

.settings_body_form_inner_field .ant-form-item-explain-error {
    font-family: Inter;
}

.ant-input-outlined .ant-input-status-error:not(.ant-input-disabled) {
    background: transparent !important;
    border-width: 1px;
    border-style: solid;
    border-color: #ff4d4f;
}

.settings_body_form_inner_field .ant-form-item-feedback-icon-success svg{
    color : #29B85D;
}
.settings_body_form_inner_field .ant-input-password-icon svg{
    color : white;
}
.settings_body_form_inner_field .ant-form-item-feedback-icon-error svg{
    color : white;
}
.settings_body_form_inner_field :where(.css-dev-only-do-not-override-1uq9j6g).ant-input-outlined{
    background: transparent!important;
    color : #FFFFFF;
}
.settings_body_form_inner_field :where(.css-1uq9j6g).ant-input-affix-wrapper{
    background: transparent!important;
}
.settings_body_form_inner_field .ant-input-password{
    height : 35px!important;
    background: transparent!important;
}
.settings_body_form_inner_field .ant-input-password input{
    height : 25px!important;
}

/*ant-input-affix-wrapper css-1uq9j6g ant-input-outlined ant-input-password*/

.settings_body_form_button {
    width: 100%;
    margin-top: 30px;
    /*display: flex;*/
    /*justify-content: center;*/
    /*margin : 10px 0;*/
}


.settings_body_form_button button {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    border-radius: 6px;
    height: 35px;
    padding: 0px 20px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 24.2px;
    text-align: center;
    color: white;
    cursor: pointer;
}

.settings_body_mentors_title{
    display : flex;
    justify-content: center;
    margin : 15px 0;
}
.settings_body_mentors_title p{
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    text-align: center;
    color: white;
}

.settings_body_mentors {
    width : 100%;
    height : fit-content;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    overflow-y: auto;
}

.settings_body_mentors_inner {
    width: 100%;
    height: 100%;
    /*padding: 30px 50px;*/
    /*background: red;*/
}


.settings_body_mentors_inner:first-child {
    /*border-right: 4px solid rgba(255, 79, 79, 0.2);*/
}

.settings_body_mentors .ant-collapse {
    border: 2px solid rgba(255, 79, 79, 0.2) !important;
    border-radius: 15px !important;
    margin-bottom: 20px!important;
}
.settings_body_mentors .ant-collapse-content-box ul li {
    margin: 10px 0;
}

.settings_body_mentors .ant-collapse-item {
    border: 0 !important;
}
.settings_body_mentors .ant-collapse-header-text{
    font-family: Inter;
    font-size: 19px!important;
    font-weight: 700!important;
    line-height: 19.36px!important;
    text-align: left;
    color: #FFFFFF!important;
}
.settings_body_mentors .ant-collapse-expand-icon{
    position: absolute!important;
    right: 0!important;
    color: rgba(255, 79, 79, 0.2) !important;
}
.settings_body_mentors .ant-collapse-expand-icon .anticon{
    font-size: 17px!important;
}
.settings_body_mentors .ant-collapse-content {
    background: transparent!important;
    font-family: Inter;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 12.1px;
    text-align: left;
    color: #FFFFFF !important;
    border: 0 !important;
    /*border-radius: 20px!important;*/
}
.settings_body_mentors .ant-collapse-content-active {
    background: transparent !important;
    border-radius: 20px !important;
    border: 0 !important;
}
.settings_body_mentors .ant-collapse-content-box {
    padding: 10px 30px!important;
}

.settings_body_mentors .ant-collapse-content-box ul li {
    margin: 15px 0;
}
.settings_body_mentors .ant-collapse-content-box ul li p{
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: #FFFFFF;
}



@media (max-width: 576px) {
    .settings_header {
        height: 40px;
    }

    .settings_header h2 {
        font-size: 14px;
    }

    .settings_body {
        height: calc(100vh - 260px);
        padding: 20px;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;
        /*height: 100%;*/
        overflow-y: auto;
    }

    .settings_body_form {
        width: 100%;
        display: block;
        /*grid-template-rows: 1fr 1fr 1fr;*/
        /*grid-gap: 30px;*/
    }

    .settings_body_form_button {
        display: flex;
        justify-content: end;
        /*margin : 10px 0;*/
    }

    .settings_body_mentors_title p{
        font-size: 16px;
    }
    .settings_body_mentors {
        /*height: auto;*/
        /*border: 4px solid rgba(255, 79, 79, 0.2);*/
        display: block;
        flex-wrap: wrap;
        /*overflow-y: auto;*/
    }
    /*.settings_body_mentors_inner{*/
    /*    padding : 10px;*/
    /*    !*height: auto;*!*/
    /*}*/
}