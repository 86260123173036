.login {
    height: 100vh;
    /*padding: 20px 47px;*/
    box-sizing: border-box;
    /*background: black;*/
}

.login_inner {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 0px;
    width: 100%;
    height: 100vh;
}
.login_inner_left{
    background: black;
    display : flex;
    align-items: center;
    padding-left:150px;
}
.login_inner_left_inner h1{
    font-family: Sequel100Black-75;
    font-size: 60px;
    font-weight: 400;
    line-height: 30.16px;
    color : #FFFFFF;
}
.login_inner_left_inner h1 span{
    color : rgba(255, 0, 0, 1);
}
.login_inner_left_inner p{
    font-family: Inter;
    font-size: 25px;
    font-weight: 400;
    line-height: 30px;
    color : #FFFFFF;
    margin : 30px 0;
}


.login_inner_right{
    display : flex;
    align-items: center;
    /*justify-content: center;*/
}

.login_inner_right_inner{
    margin : 0 auto;
    width : 100%;
    padding : 0 70px;
}
@media(max-width: 1500px) {
    .login_inner_right_inner{
        margin : 0 auto;
        width : 100%;
        padding : 0 30px;
    }
}
.login_inner_right_inner h1{
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}
.login_inner_right_inner p{
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: rgba(99, 108, 121, 1);
}
.login_inner_right_inner_form {
    margin : 15px 0;
}
.login_inner_right_inner_form_button button {
    width : 100%;
    height : 40px;
    border : 0;
    outline : none;
    background: linear-gradient(90deg, #FA4343 0%, #A50000 100%);
    font-family: Sequel100Black-75;
    font-size: 12px;
    font-weight: 400;
    line-height: 18.1px;
    color : #FFFFFF;
    border-radius: 10px;
    cursor : pointer;
}
.login_inner_right_inner_form .ant-input {
    /*height : 40px;*/
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    height: 45px;
    border-radius: 10px!important;
}


.login_inner_right_inner_form .ant-input-password {
    height: 45px;
    border-radius: 10px;
}

.login_inner_right_inner_form .ant-input-password .ant-input {
    height: 35px;
}

.login_inner_right_inner_form .ant-input-suffix svg {
    /*color: aliceblue;*/
}
.login_inner_right_inner_form .ant-input-affix-wrapper{

}
.login_inner_right_inner_form .ant-input-affix-wrapper:hover{
    border: 0.5px solid rgba(193, 193, 193, 1) !important;
}
.login_inner_right_inner_form .ant-input::placeholder {

}

.login_inner_right_inner_form .ant-form-item-explain-error {
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
}

@media(max-width: 576px) {
    .login{
        width : 100%;
    }
    .login_inner {
        display: inline-block;
        /*grid-template-rows: 1fr 1fr;*/
        /*grid-gap: 0px;*/
        width: 100%;
        height: 100vh;
    }
    .login_inner_left {
        background: black;
        height : 150px;
        padding-left:0px;
        padding : 20px;
    }
    .login_inner_left_inner{
        width : 100%;
    }
    .login_inner_left_inner h1{
        font-size: 28px;
        color : #FFFFFF;
        text-align: center;
    }
    .login_inner_left_inner h1 span{
        color : rgba(255, 0, 0, 1);
    }
    .login_inner_left_inner p{
        font-size: 17px;
        margin : 10px 0;
        text-align: center;
    }

    .login_inner_right{
        height : calc(100vh - 150px);
        display : flex;
        align-items: center;
    }
    .login_inner_right_inner{
        /*margin : 70px 0;*/
    }
}