.certificate_test {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    /*z-index: 1000;*/
    background: black;
    background-image: url('../../../../images/user/userDashboard/back2.png');
    /*background-size: 100%;*/
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px 30px;
    box-sizing: border-box;
}

.certificate_test_head {
    border-bottom: 1px solid rgba(192, 183, 232, 0.33);
    display: flex;
    align-items: center;
    height: 60px;
    /*background: green;*/
}

.certificate_test_head_exit {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    width: 144px;
    height: 30px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.certificate_test_head_exit h1 {
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
    color: white;
    display: flex;
    align-items: center;
}

.certificate_test_head_exit h1 img {
    margin-right: 12px;
}

.certificate_test_body {
    /*background: green;*/
    height: calc(100vh - 100px);
    overflow-y: auto;
    padding: 20px 200px;
    box-sizing: border-box;
}

.certificate_test_body_test_text {
    background: rgba(23, 23, 23, 1);
    /*height: 100px;*/
    border-radius: 10px;
    padding: 25px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.certificate_test_body_test_text h2 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 800;
    line-height: 29.05px;
    color: white;
}

.certificate_test_body_radio {
    margin: 20px 0;
    height: 200px;
}

.certificate_test_body_radio .ant-radio-inner {
    border: 2px solid !important;
    border-color: rgba(244, 244, 244, 1) !important;
    background: transparent !important;
    width: 20px !important;
    height: 20px !important;
}

.certificate_test_body_radio .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    background: rgba(255, 79, 79, 1) !important;
    border-color: rgba(255, 79, 79, 1) !important;
}

.certificate_test_body_radio .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
    opacity: 0;
}

.certificate_test_body_radio .ant-radio-wrapper .ant-radio-checked::after {
    border: 0 !important;
}

.certificate_test_body_radio .ant-radio-group span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 800;
    line-height: 19.36px;
    color: white;
}

.certificate_test_body_radio .ant-space-item {
    margin: 10px 0;
}

.certificate_test_body_next_button {
    display: flex;
    justify-content: end;
    margin: 30px 0;
}

.certificate_test_body_next_button button {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: center;
    color: white;
    outline: none;
    border: 0;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
}

.disabled_button {
    background: #484848 !important;
}

.certificate_test_body_progress {
    /*display : flex;*/
    /*align-items: center;*/
}

.certificate_test_body_progress .ant-progress .ant-progress-bg {
    background: rgba(255, 79, 79, 1);
}

.ant-progress.ant-progress-status-success .ant-progress-bg {
    background-color: #52c41a !important;
}

.certificate_test_body_progress .ant-progress .ant-progress-inner {
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.certificate_test_body_progress p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    color: white;
    text-align: center;
}


.exit_modal {
    width: 320px;
    height: 100px;
    border-radius: 10px;
    background: rgba(23, 23, 23, 1);
    /*z-index: 1000000;*/
    padding: 20px;
}

.exit_modal_text h2 {
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 19.36px;
    color: white;
    text-align: center;
}

.exit_modal_buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 20px 0;
}

.exit_modal_buttons button {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: center;
    padding: 8px 20px;
    border-radius: 10px;
    border: 0;
    outline: none;
    width: 100px;
    cursor: pointer;
}
.exit_modal_buttons button:first-child{
    border: 1px solid rgba(244, 244, 244, 1);
    background: rgba(23, 23, 23, 1);
    color : white;
}
.exit_modal_buttons button:last-child{
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    color : white;
}


@media (max-width: 576px) {
    .certificate_test {
        padding: 20px 20px;
    }

    .certificate_test_body {
        padding: 10px 15px;
    }

    .certificate_test_body_test_text {
        padding: 20px;
    }

    .certificate_test_body_test_text h2 {
        font-family: Inter;
        font-size: 14px;
        font-weight: 800;
        line-height: 20px;
    }
}
