

.library_body_modal_inner .ant-form-item-control-input-content textarea{
    height : 70px;
}

.exit_modal_premium {
    width: 320px;
    height: 160px;
    border-radius: 10px;
    background: rgba(23, 23, 23, 1);
    /*z-index: 1000000;*/
    padding: 20px;
}
.exit_modal_premium_body{
    display: flex;
    align-items: center;
    justify-content: center;
    /*background: red;*/
    height : 30px;
    margin-top : 20px;
}
.exit_modal_premium_body .ant-form-item {
    margin-bottom : 0!important;
}
.exit_modal_premium_body .ant-radio-wrapper span{
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.36px;
    color: white;
}
.exit_modal_premium_text h2 {
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 19.36px;
    color: white;
    text-align: center;
}

.exit_modal_premium_buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 20px 0;
}

.exit_modal_premium_buttons button {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: center;
    padding: 8px 20px;
    border-radius: 10px;
    border: 0;
    outline: none;
    width: 100px;
    cursor: pointer;
}
.exit_modal_premium_buttons button:first-child{
    border: 1px solid rgba(244, 244, 244, 1);
    background: rgba(23, 23, 23, 1);
    color : white;
}
.exit_modal_premium_buttons button:last-child{
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    color : white;
}

.library_body_modal_inner .ant-form-item-feedback-icon-success svg{
    color : #29B85D;
}
.library_body_modal_inner .ant-input-password-icon svg{
    color : white;
}
.library_body_modal_inner .ant-form-item-feedback-icon-error svg{
    color : white;
}
:where(.css-dev-only-do-not-override-1uq9j6g).ant-input-outlined{
    background: transparent!important;
    color : #FFFFFF;
}
.library_body_modal_inner .ant-input-password{
    height : 40px!important;
}
.library_body_modal_inner .ant-input-password input{
    height : 30px!important;
}