.links {
    /*height: 100%!important;*/
    overflow-y: auto;
}

.links_header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.links_header button{
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    border: 0;
    outline: none;
    height: 30px;
    border-radius: 10px;
    padding: 8px 10px;
    cursor: pointer;
}

.links_header h2 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
    color: #FFFFFF;
    text-align: center;
}

.links_body {
    height: calc(100vh - 145px);
    border: 4px solid rgba(255, 79, 79, 0.2);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0;
    overflow-y: auto;
}

.links_body_inner {
    width: 100%;
    height: 100%;
    padding: 20px;

}

.links_body_inner:first-child {
    border-right: 4px solid rgba(255, 79, 79, 0.2);
}
.links_body_inner_collapse{
    display : flex;
    align-items: center;
    justify-content: center;
    margin-bottom : 20px;
    /*height : 40px!important;*/
}

.links_body_inner_collapse_icons{
    display : flex;
    align-items: center;
    justify-content: space-between;
    width : 25%;
}
.links_body_inner_collapse_icons svg{
    width : 20px;
    height : 20px;
    cursor : pointer;
    font-weight: 700;
    margin : 0 10px;
}
.links_body_inner_collapse_icons .table_icons_add svg{
    color: white;
}
.links_body_inner_collapse_icons .table_icons_edit svg{
    color: #52c41a;
}
.links_body_inner_collapse_icons .table_icons_delete svg{
    color: rgba(255, 79, 79, 1);
}




.links_body .ant-collapse {
    border: 2px solid rgba(255, 79, 79, 0.2) !important;
    border-radius: 15px !important;
    /*margin-bottom: 20px!important;*/
    width : 80% !important
}

.links_body .ant-collapse-item {
    border: 0 !important;
}
.links_body .ant-collapse-header-text{
    font-family: Inter;
    font-size: 16px!important;
    font-weight: 700!important;
    line-height: 19.36px!important;
    text-align: left;
    color: #FFFFFF!important;
}
.links_body .ant-collapse-expand-icon{
    position: absolute!important;
    right: 0!important;
    color: rgba(255, 79, 79, 0.2) !important;
    height : 17px!important;
}
.links_body .ant-collapse-expand-icon .anticon{
    font-size: 16px!important;
}
.links_body .ant-collapse-content {
    background: transparent!important;
    font-family: Inter;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 12.1px;
    text-align: left;
    color: #FFFFFF !important;
    border: 0 !important;
    /*border-radius: 20px!important;*/
}
.links_body .ant-collapse-content-active {
    background: transparent !important;
    border-radius: 20px !important;
    border: 0 !important;
}
.links_body .ant-collapse-content-box{
    padding: 10px 30px!important;
}


.links_body .ant-collapse-content-box ul li {
    margin: 15px 0;
    /*display : flex;*/
    /*align-items: center;*/
    /*justify-content: space-between;*/
    /*background : green;*/
}

/*responsive*/

@media (max-width: 576px) {
    .links_header h2 {
        font-size: 14px;
    }
    .links_body {
        height: calc(100vh - 260px);
        /*height: 100%;*/
        /*overflow-y: auto;*/
        border: 4px solid rgba(255, 79, 79, 0.2);
        display: block;
        flex-wrap: wrap;
        /*grid-template-columns: 1fr 1fr;*/
        /*grid-gap: 0;*/
        overflow-y: auto;
    }
    .links_body_inner{
        padding : 10px;
        height: auto;
    }

    .links_body_inner:first-child {
        border-right: 0px solid rgba(255, 79, 79, 0.2);
    }

    .links_body_inner_collapse_icons{
        width : 25%;
        /*background: red;*/
    }
    .links_body_inner_collapse_icons svg{
        margin : 0 5px;
    }
    .links_body .ant-collapse {
        width : 70% !important
    }

    .links_body .ant-collapse-header-text{
        font-size: 12px!important;
    }
    .links_body .ant-collapse-expand-icon{
        position: absolute!important;
        right: 0!important;
        color: rgba(255, 79, 79, 0.2) !important;
    }
    .links_body .ant-collapse-expand-icon .anticon{
        font-size: 17px!important;
    }
    .links_body .ant-collapse-content {

        font-size: 10px !important;

    }

}
