.landing_adds {
    /*overflow-y: auto;*/
}

.landing_adds_header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.landing_adds_header h2 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
    color: #FFFFFF;
    text-align: center;
}

.landing_adds_header button {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    border: 0;
    outline: none;
    height: 30px;
    border-radius: 10px;
    padding: 8px 10px;
    cursor: pointer;
}

.landing_adds_body {
    height: calc(100vh - 145px);
    /*overflow: auto;*/
}

.landing_adds_body_inner {
    height: 100%;
    border: 4px solid rgba(255, 79, 79, 0.2);
    padding: 10px 15px;
    box-sizing: border-box;
}


.landing_adds_body_inner_inner {
    /*background: #52c41a;*/
    /*height: 100%;*/
    height: calc(100vh - 205px);
    overflow-y: auto;
    padding-bottom: 30px;
}

.landing_adds_body_inner .ant-tabs-nav {
    height: 35px !important;
    margin: 0 !important;
}

.landing_adds_body_inner .ant-tabs-tab {
    border-radius: 0 0px 0px 0 !important;
    /*background: red !important;*/
}

.landing_adds_body_inner .ant-tabs-tab .ant-tabs-tab-active .ant-tabs-tab-btn {
    /*color: rgba(255, 79, 79, 1)!important;*/
}

.landing_adds_body_inner .ant-tabs-tab-active {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%) !important;
    /*color: rgba(255, 79, 79, 1)!important;*/
}

.landing_adds_body_inner .ant-tabs-tab:hover .ant-tabs-tab-btn {
    color: rgba(255, 79, 79, 1) !important;
}

.landing_adds_body_inner .ant-tabs-tab-active:hover .ant-tabs-tab-btn {
    color: white !important;
}

.landing_adds_body_inner .ant-tabs-tab .ant-tabs-tab-btn {
    color: #FFFFFF !important;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 14.52px;
    text-align: center;
}

.landing_adds_body_inner_inner_footer {
    position: absolute;
    bottom: 0;
    left: 0;
}

.landing_adds_body_inner_inner_footer button {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    border: 0;
    outline: none;
    height: 30px;
    border-radius: 4px;
    padding: 8px 10px;
    cursor: pointer;
}

/*table style*/
.landing_adds_body_inner_inner_table .ant-table {

}

.landing_adds_body_inner_inner_table .ant-table {
    border-radius: 0 !important;
    /*height: 100%!important;*/
}

.landing_adds_body_inner_inner_table .ant-table-content {

}

.landing_adds_body_inner_inner_table .ant-table-cell {
    /*background: blue!important;*/
    border-radius: 0 !important;
    font-family: Inter;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 19.36px !important;
    color: #FFFFFF !important;
    text-align: center !important;
}

.landing_adds_body_inner_inner_table .ant-table {
    background: transparent !important;
}

.landing_adds_body_inner_inner_table .ant-table-thead .ant-table-cell {
    border-bottom: 4px solid rgba(255, 79, 79, 0.2) !important;
    background: transparent !important;
    /*position: sticky;*/
    /*top : 0;*/
    z-index: 100;
}

.landing_adds_body_inner_inner_addModal_inner .ant-form-item-control-input-content textarea{
    height : 70px;
}

.landing_adds_body_inner_inner_table .ant-table-tbody {
    /*background: green!important;*/
    /*    height:100%!important;*/
    /*    overflow-y: auto!important;*/
}

.landing_adds_body_inner_inner_table .ant-empty-description {
    display: none;
}

.landing_adds_body_inner_inner_table .ant-table-tbody .ant-table-cell {
    border: 0 !important;
}

.landing_adds_body_inner_inner_table .ant-table-tbody .ant-table-cell-row-hover {
    background: rgba(255, 79, 79, 0.2) !important;
}

.landing_adds_body_inner_inner_table .ant-table-placeholder {
    background: transparent !important;
}

.landing_adds_body_inner_inner_table .ant-table-placeholder:hover {
    background: transparent !important;
}

.landing_adds_body_inner_inner_table .ant-table-placeholder:hover {
    background: rgba(255, 79, 79, 0.2) !important;
}

.landing_adds_body_inner_inner_table .ant-table-thead tr .ant-table-cell {
    height: 20px !important;
    padding: 10px 10px !important;
}

.landing_adds_body_inner_inner_addModal_inner {
    background: rgba(23, 23, 23, 1);
    width: 350px;
    height: auto;
    padding : 10px 20px;
}
.landing_adds_body_inner_inner_addModal_inner_text{
    padding : 10px 0;
}
.landing_adds_body_inner_inner_addModal_inner_text h2 {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 29.05px;
    text-align: center;
    color: #FFFFFF;
}
.landing_adds_body_inner_inner_addModal_inner {

}
.landing_adds_body_inner_inner_addModal_inner .ant-form-item-required{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    /*line-height: 29.05px;*/
    text-align: center;
    color: #FFFFFF!important;
}
.landing_adds_body_inner_inner_addModal_inner .ant-input {
    border-color: white;
    /*background: rgba(23, 23, 23, 1);*/
    background: transparent;
    font-family: Inter;
    font-size: 15px;
    font-weight: 600;
    color: white;
    height: 35px;
    /*line-height: 24.2px;*/
}

.landing_adds_body_inner_inner_addModal_inner .ant-input::placeholder {
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    color: gray;
    /*line-height: 24.2px;*/
}

.landing_adds_body_inner_inner_addModal_inner .ant-input:hover {
    border-color: white;
}

/*class="ant-input ant-input-status-error css-dev-only-do-not-override-gzal6t css-dev-only-do-not-override-gzal6t"*/
.landing_adds_body_inner_inner_addModal_inner .ant-input-status-error .ant-input {
    border-color: #ff4d4f !important;
}

.landing_adds_body_inner_inner_addModal_inner input::-webkit-inner-spin-button {
    display: none;
}

.landing_adds_body_inner_inner_addModal_inner .ant-form-item-explain-error {
    font-family: Inter;
}

.landing_adds_body_inner_inner_addModal_inner .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
    background: transparent !important;
    border-width: 1px;
    border-style: solid;
    border-color: #ff4d4f;
}


.landing_adds_body_inner_inner_addModal_inner_buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}

.landing_adds_body_inner_inner_addModal_inner_buttons button {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: center;
    padding: 8px 10px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    width: 120px;
    height: 35px;
    cursor: pointer;
}

.landing_adds_body_inner_inner_addModal_inner_buttons button:first-child {
    border: 1px solid rgba(244, 244, 244, 1);
    background: rgba(23, 23, 23, 1);
    color: white;
}

.landing_adds_body_inner_inner_addModal_inner_buttons button:last-child {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    color: white;
}

.table_icons{
    display : flex;
    align-items: center;
    justify-content: center;
}
.table_icons svg{
    width : 20px;
    height : 20px;
    cursor : pointer;
    font-weight: 700;
    margin : 0 10px;
}
.table_icons_edit svg{
    color: #52c41a;
}
.table_icons_delete svg{
    color: rgba(255, 79, 79, 1);
}



.landing_adds .exit_modal {
    width: 320px;
    height: 100px;
    border-radius: 10px;
    background: rgba(23, 23, 23, 1);
    /*z-index: 1000000;*/
    padding: 20px;
}

.landing_adds .exit_modal_text h2 {
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 19.36px;
    color: white;
    text-align: center;
}

.landing_adds .exit_modal_buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 20px 0;
}

.landing_adds .exit_modal_buttons button {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: center;
    padding: 8px 20px;
    border-radius: 10px;
    border: 0;
    outline: none;
    width: 100px;
    cursor: pointer;
}
.landing_adds .exit_modal_buttons button:first-child{
    border: 1px solid rgba(244, 244, 244, 1);
    background: rgba(23, 23, 23, 1);
    color : white;
}
.landing_adds .exit_modal_buttons button:last-child{
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    color : white;
}

.landing_adds_body_inner_inner_table_video .ant-image-mask {
    border-radius : 50% !important;
}
.landing_adds_body_inner_inner_table_img .ant-image-img {
    object-fit : contain;
}

/*img upload*/
.user_list_modal_uploadImg {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap : 10px;
    align-items: center;
    margin-top: 10px;
}
.ant-input{
    border-radius: 5px!important;
}

.user_list_modal_uploadImg_left {
    background: #F1F3F6;
    border-radius: 10px;
    height: 90px!important;
    width: 90px!important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user_list_modal_uploadImg_left .img1 {
    height: 90px;
    width: 90px;
    margin-top :6px;
    border-radius: 10px;
    /*object-fit : contain;*/
}

.user_list_modal_uploadImg_left .img2 {
    height: 100%;
    width: 100%;
    border-radius: 10px;
}

.user_list_modal_uploadImg_right {
    /*margin-left: 15px;*/
}
.upload_button{
    /*background: red!important;*/
}
.upload_button_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(129, 141, 174, 0.11);
    border-radius: 7px;
    padding: 5px 10px;
    box-sizing: border-box;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #AEB7C5;
    width: 150px;
    cursor: pointer;
}

.upload_button_icon:hover {
    background: #8B8E96;
    color: #DDDEE2;
}

.upload_button_text {
    margin-top: 5px;
}

.upload_button_text span {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #AEB7C5;
}


/*img upload*/

@media(max-width: 576px) {
    .landing_adds_header {
        height: 40px;
    }

    .landing_adds_header h2 {
        font-size: 14px;
    }
    .landing_adds_body {
        height: calc(100vh - 260px);
        /*height: 100%;*/
        /*overflow-y: auto;*/
    }
    .landing_adds_body {
        height: calc(100vh - 260px);
        /*padding: 20px;*/
        /*display: grid;*/
        /*grid-template-columns: 1fr;*/
        /*grid-gap: 10px;*/
        /*!*height: 100%;*!*/
        /*overflow-y: auto;*/
    }
    .landing_adds_body_inner_inner {
        /*background: #52c41a;*/
        /*height: 100%;*/
        height: calc(100vh - 320px);
        overflow-y: auto;
        padding-bottom: 30px;
    }
    .landing_adds_body_inner_inner_table_video_player{
        width : 330px!important;
        height : 400px!important;
    }
}