.certificate_pdf {
    height: calc(100vh - 105px);
    overflow-y: auto;
    /*display: flex;*/
    /*justify-content: center;*/
    /*background: green;*/
    position :absolute;
    top : -1500px;
    left : -1500px;
}


.certificate_pdf_body {
    width: 841px;
    height: 1189px;
    /*height : 1132px;*/
    padding: 10px;
    background: rgba(0, 0, 0, 1);
    position: relative;
    /*margin: 0 auto;*/
    overflow: hidden;

}

.certificate_pdf_body_inner_bio_inner2 {
    height: 400px;
    width: 400px;
    position: absolute;
    top: -10%;
    right: -25%;
    padding: 15px;
    box-sizing: border-box;
    background: black;
}

.certificate_pdf_body_inner_bio_inner2 img {
    height: 100%;
    width: 100%;
    transform: rotate(30deg);
    /*background: black;*/
}


.certificate_pdf_body_inner {
    border: 2px solid rgba(255, 0, 0, 1);
    width: 100%;
    height: 100%;
    padding: 50px 0;
}

.certificate_pdf_body_inner_text {
    /*height : 120px;*/
    display: flex;
    align-items: center;
    background: black;
    padding: 10px 0px;
    position: absolute;
    left: 0;
    /*top: 50px;*/
}

.certificate_pdf_body_inner_text_inner {
    display: flex;
    align-items: center;
}

.certificate_pdf_body_inner_text_inner_pro {
    font-family: Sequel100Black-75;
    font-size: 95px;
    font-weight: 400;
    line-height: 130px;
    color: black;
    background: rgba(255, 0, 0, 1);
    padding: 0 20px;
    height: 110px;
    display: flex;
    align-items: end;
}

.certificate_pdf_body_inner_text_inner_column {
    height: 110px;
    width: 30px;
    background: rgba(255, 0, 0, 1);
    margin-left: 20px;
}

.certificate_pdf_body_inner_text_inner_column:nth-child(2) {
    width: 28px;
}

.certificate_pdf_body_inner_text_inner_column:nth-child(3) {
    width: 24px;
}

.certificate_pdf_body_inner_text_inner_column:nth-child(4) {
    width: 18px;
}

.certificate_pdf_body_inner_text_inner_column:nth-child(5) {
    width: 14px;
}

.certificate_pdf_body_inner_text_inner_column:nth-child(6) {
    width: 12px;
}

.certificate_pdf_body_inner_text_inner_column:nth-child(7) {
    width: 8px;
}

.certificate_pdf_body_inner_text_inner_column:nth-child(8) {
    width: 6px;
}

.certificate_pdf_body_inner_text_inner_column:nth-child(9) {
    width: 3px;
}

.certificate_pdf_body_inner_text_inner_column:nth-child(10) {
    width: 2px;
}


.certificate_pdf_body_inner_text_inner2 .certificate_pdf_body_inner_text_inner_column {
    height: 70px;
    width: 30px;
    background: rgba(255, 0, 0, 1);
    margin-left: 20px;
}

.certificate_pdf_body_inner_text_inner_column:nth-child(2) {
    width: 28px;
}

.certificate_pdf_body_inner_text_inner_column:nth-child(3) {
    width: 24px;
}

.certificate_pdf_body_inner_text_inner_column:nth-child(4) {
    width: 18px;
}

.certificate_pdf_body_inner_text_inner_column:nth-child(5) {
    width: 14px;
}

.certificate_pdf_body_inner_text_inner_column:nth-child(6) {
    width: 12px;
}

.certificate_pdf_body_inner_text_inner_column:nth-child(7) {
    width: 8px;
}

.certificate_pdf_body_inner_text_inner_column:nth-child(8) {
    width: 6px;
}

.certificate_pdf_body_inner_text_inner_column:nth-child(9) {
    width: 3px;
}

.certificate_pdf_body_inner_text_inner_column:nth-child(10) {
    width: 2px;
    margin-left: 10.5px;
}

.certificate_pdf_body_inner_text2 {
    /*height : 120px;*/
    display: flex;
    align-items: center;
    background: black;
    padding: 10px 0px;
    margin-top: 150px;
    /*left: 0;*/
    /*top: 50px;*/
}

.certificate_pdf_body_inner_text_inner2 {
    display: flex;
    align-items: center;
    background: black;
    padding-right: 5px;
}

.certificate_pdf_body_inner_text_inner_pro2 {
    font-family: Sequel100Black-75;
    font-size: 95px;
    font-weight: 400;
    line-height: 68px;
    color: white;
    /*background: rgba(255, 0, 0, 1);*/
    padding: 0 10px;
    height: 90px;
    /*display: flex;*/
    /*align-items: end;*/
}

.certificate_pdf_body_inner_title {
    margin: 15px 0;
}

.certificate_pdf_body_inner_title p {
    font-family: BebasNeue;
    font-size: 16px;
    font-weight: 400;
    line-height: 14.13px;
    letter-spacing: 0.28em;
    text-align: center;
    color: rgba(255, 255, 255, 1);
}

.certificate_pdf_body_inner_name {
    /*margin: 40px 0;*/
    margin-top: 40px;
    /*background: green;*/
}

.certificate_pdf_body_inner_name_title {
    display: flex;
    align-items: center;
    justify-content: center;

}

.certificate_pdf_body_inner_name_title_inner {
    height: 45px;
    background: rgba(255, 0, 0, 1);
    display: flex;
    align-items: center;
    transform: scale(0.9);
}

.certificate_pdf_body_inner_name_title p {
    font-family: Sequel100Black-75;
    font-size: 18px;
    font-weight: 400;
    line-height: 27.14px;
    color: black;
    padding: 0 5px;
    margin: 0;
}

.certificate_pdf_body_inner_name_user p {
    font-family: Sequel100Black-75;
    font-size: 40px;
    font-weight: 400;
    line-height: 45px;
    margin-top : 5px;
    text-align: center;
    color: white;
    text-transform: uppercase;
}

.certificate_pdf_body_inner_bio {
    margin-top: 50px;
    padding: 0 35px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    position: relative;
}

.certificate_pdf_body_inner_bio_title p {
    font-family: BebasNeue;
    font-size: 30px;
    font-weight: 400;
    line-height: 35.32px;
    letter-spacing: 0.1em;
    color: rgba(255, 0, 0, 1);
}

.certificate_pdf_body_inner_bio_bio {
    margin: 25px 0;
}

.certificate_pdf_body_inner_bio_bio p {
    font-family: BebasNeue;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.2em;
    text-align: left;
    color: white;
}

.certificate_pdf_body_inner_bio_bio p span {
    color: rgba(255, 0, 0, 1);
}


.certificate_pdf_body_inner_bio_date p {
    font-family: BebasNeue;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    letter-spacing: 0.2em;
    text-align: left;
    color: white;
}

.certificate_pdf_body_inner_footer {
    /*margin-top: 70px;*/
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 35px;
    box-sizing : border-box;
    position : absolute;
    bottom : 70px;
    width : 97%;
    /*background : green;*/
}

.certificate_pdf_body_inner_footer_text p {
    font-family: BebasNeue;
    font-size: 60px;
    font-weight: 400;
    line-height: 50px;
    /*text-align: left;*/
    color: rgba(255, 0, 0, 1);
    margin: 0;
    padding: 0;
}

.certificate_pdf_body_inner_footer_qrCode {
    border: 1px solid rgba(255, 0, 0, 1);
    height: 140px;
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    /*padding : 10px;*/
}

.certificate_pdf_body_inner_footer_qrCode canvas {
    width: 125px !important;
    height: 125px !important
}

.certificate_pdf_modal_button {
    /*background: green;*/
    display: flex;
    justify-content: center;
    margin: 10px 0;
}

.certificate_pdf_modal_button button {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: center;
    color: white;
    border-radius: 40px;
    padding: 5px 20px;
    border: 0;
    outline: none;
    cursor: pointer;
}