.not_found {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.not_found_inner {
    text-align: center;
}
.not_found_inner h1 {
    font-family: Inter;
    font-size: 60px;
    font-weight: 700;
    line-height: 29.05px;
    color: white;
}
.not_found_inner p {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    color: white;
    margin-top : 20px;
}
