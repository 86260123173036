.loader{
    position: absolute;
    left: 0%;
    top: 0%;
    z-index: 500;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
    height: 100%;
    width: 100%;
    opacity: 5;
    transition: opacity 0.3s;
}